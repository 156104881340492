import { z } from "zod";


export const itemlineSchema = z.object({
    itemNo: z.string().max(50),
    countryOfOrigin: z.string().max(2),
    hsTariffNumber: z.string().max(12),
    description: z.string().max(50),
    weight: z.number().min(0),
    value: z.number().min(0),
    category: z.string().max(50),
    quantity: z.number().min(1),
});

export type ItemlineType = z.infer<typeof itemlineSchema>;