import { z } from "zod";
import { goodslineSchema } from "./Goodsline";
import { documentsSchema } from "./Document";
import { itemlineSchema } from "./Itemline";
import { shipment_NeededFieldsSchema } from "./Shipment_NeededFields";


export const shipmentSchema = z.object({
    shipmentId: z.string().optional(),
    status: z.number(),
    dispDate: z.string().transform((v) => `${v}T00:00:00.000Z`).pipe( z.string().datetime({ message: 'incorrect format' }) ),
    companyName: z.string().max(50),
    street1: z.string().max(32, { message: 'validation.invalidMaxLength' }),
    street2: z.string().max(32, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    street3: z.string().max(32, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    countryCode: z.string().min(2).max(2),
    zipCode: z.string().min(1, { message: 'validation.canNotBeEmpty'}).max(30),
    city: z.string(),
    
    trpPayer: z.number().int().min(1).max(2),
    gaNo: z.string().max(50).nullish().optional(),
    
    contactName: z.string().max(50).nullish().optional(),
    contactPhone: z.string().max(50).nullish().optional(),
    contactMobileNo: z.string().max(50).nullish().optional(),
    contactEmail: z.string().max(50).nullish().optional(),
    
    deliveryType: z.string(),
    carrier: z.string().nullish().optional(),
    orderNo: z.string().nullish().optional(),
    // customerNumber: z.string().nullish().optional(),
    
    isBooked: z.boolean().nullish().optional(),
    TA_Status: z.enum(['10', '20', '80', '90']).optional(),
    trackingLink: z.string().nullish().optional(),

    goodsLines: z.array(goodslineSchema).nullish().optional(),
    itemLines: z.array(itemlineSchema).nullish().optional(),
    documents: z.array(documentsSchema).nullish().optional(),
    neededFields: z.array(shipment_NeededFieldsSchema).nullish().optional(),
    ValidationError: z.string().nullish().optional(),
    noteInternal: z.string().nullish().optional(),
    checked: z.boolean().nullish().optional()

    

});

export type ShipmentType = z.infer<typeof shipmentSchema>;