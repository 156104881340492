import React, {FC, useState, useEffect, useId} from 'react'
import "../../assets/style/Select.css"
import { useTranslate } from '../../hooks'

type dataItem = {
  name: string,
  value: string | number
}

type Props = {
  label?: string,
  _ref?: React.MutableRefObject<null>,
  data: dataItem[],
  //onChange?: (t:React.ChangeEvent<HTMLInputElement>) => void,
  onChange?: (t:React.ChangeEvent<HTMLSelectElement>) => void,
  required?: boolean
  value?: string | number
  blankOption?: boolean;
  style?: any;
  readOnly?: boolean;
  error?: string;
  isDatalist?: boolean;
  [key:string]: any;
}

/**
 * 
 * @param label - The label for the select element
 * @param _ref A ref object to reference the select element
 * @param {Array<{name:string, value:string}>} data - An array of objects representing the options for the select element.
 * @param {function} onChange - A callback function that will be ran when a user changes the value.
 * @param {string | number} value - The value of the Select.
 * @param {boolean} required - Whether the select is required/mandatory in a form or not.
 * @param {boolean} blankOption - Adds a blank disabled option in the beginning.
 * @param {boolean} isDatalist - Converts the select to a datalist instead.
 * @returns 
 */
const Select: FC<Props> = ({label, _ref, data, onChange, value, required, blankOption, style, readOnly, error, isDatalist, ...props}) => {

  const { t } = useTranslate();

  const [ curValue, setCurValue ] = useState<string | number>(value ?? "")
  const [ filteredList, setFilteredList] = useState(data)
  const [ selectedIndex, setSelectedIndex] = useState(0)
  const [ refValue, setRefValue] = useState<string | number | undefined>(curValue)
  const id = useId()

  useEffect(() => {
    let arr: dataItem[] = [...[],...data]

    arr = arr.reduce((pre:dataItem[], cur:dataItem) => {
      if (curValue === ""){
        pre.push(cur)
        return pre
      } 
      let reg = new RegExp(curValue.toString().toLocaleLowerCase(), "g")

      if (cur.name.toLocaleLowerCase().match(reg)) 
        pre.push(cur)      
      return pre
    }, [])

    setFilteredList(arr)    
    setRefValue(filteredList.find(d => d.name === curValue)?.value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curValue, selectedIndex, data])

  if(isDatalist){
    return (<div className='relative' style={style}>
      <input className="w-full text-sm py-1 pl-1 border-schipt-dark-gray border-solid border rounded-sm bg-white dark:bg-schipt-dark font-montserrat focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer"
        onChange={(v) => onChange?.((v as unknown) as React.ChangeEvent<HTMLSelectElement>)} value={value} required={required} disabled={readOnly} list={id}
        {...props}
      />      
      <datalist id={id}>
      {
        data?.length > 0 && data.map((d, i) => {
          return <option value={d.value} key={i} onFocus={(e) => console.log({e})}>{d.name}</option>
        })
      }
      </datalist>
      <label className="absolute left-0 -top-5  text-schipt-dark-gray text-xs font-semibold font-montserrat peer-focus:-top-5 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2">{label}</label> 
      {error ? <><br /><p className='absolute -bottom-3.5 text-xs text-rose-500 w-100'>{error}</p></> : <></>}
    </div>)
  }

  return (<div className='relative w-full md:w-auto' style={style}>
    <select className="w-full text-sm py-1 pl-1 border-schipt-dark-gray border-solid border rounded-sm bg-white dark:bg-schipt-dark font-montserrat focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer"
      onChange={(v) => onChange?.(v)} value={value} required={required} disabled={readOnly}
      {...props}
    >
    <option disabled={!blankOption} value="" key={-1}>{t("chooseAnOption")}</option>
    {
      data?.length > 0 && data.map((d, i) => {
        return <option value={d.value} key={i}>{d.name}</option>
      })
    }
    </select>
    <label className="absolute left-0 -top-5  text-schipt-dark-gray dark:text-schipt-white-90 text-xs font-semibold font-montserrat peer-focus:-top-5 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2">{label}</label> 
    {error ? <><br /><p className='absolute -bottom-3.5 text-xs text-rose-500 w-100'>{error}</p></> : <></>}
  </div>)    
}

export default Select